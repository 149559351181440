// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `figure.table>table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #d3d3d3;
    margin: 0 auto;
    padding: 0;
    table-layout: fixed;
    width: 100%;
}

tr {
    border-bottom: 1px solid #d3d3d3;
}

td,
th {
    border-right: 1px solid #d3d3d3;
    padding: 0.5rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/components/inserted-html/insertedHTML.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;IACd,UAAU;IACV,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,gCAAgC;AACpC;;AAEA;;IAEI,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":["figure.table>table {\n    width: 100%;\n    border-spacing: 0;\n    border-collapse: collapse;\n    border: 1px solid #d3d3d3;\n    margin: 0 auto;\n    padding: 0;\n    table-layout: fixed;\n    width: 100%;\n}\n\ntr {\n    border-bottom: 1px solid #d3d3d3;\n}\n\ntd,\nth {\n    border-right: 1px solid #d3d3d3;\n    padding: 0.5rem;\n    text-align: left;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
