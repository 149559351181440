// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 8rem;
    overflow: hidden;
    padding: var(--content-padding-small);
    padding: 2rem 0;
}

.info-column {
    flex-basis: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.info-row.row-reverse .info-column {
    flex-direction: column-reverse;
}

.info-text>h3 {
    margin-bottom: 1rem;
}

.info-row.row-reverse .info-text>h3 {
    text-align: center;
}

`, "",{"version":3,"sources":["webpack://./src/components/info-row/infoRow.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,oBAAoB;IACpB,SAAS;IACT,gBAAgB;IAChB,qCAAqC;IACrC,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".info-row {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: stretch;\n    gap: 8rem;\n    overflow: hidden;\n    padding: var(--content-padding-small);\n    padding: 2rem 0;\n}\n\n.info-column {\n    flex-basis: 20rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.info-row.row-reverse .info-column {\n    flex-direction: column-reverse;\n}\n\n.info-text>h3 {\n    margin-bottom: 1rem;\n}\n\n.info-row.row-reverse .info-text>h3 {\n    text-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
