// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-container{
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.grid-container.logo-container{
    padding-top: 2rem;
}
.logo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 150px;
}

.logo__img{
    width: 80%;
    height: 80%;
    object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/components/logos/logos.css"],"names":[],"mappings":"AAAA;IACI,4DAA4D;AAChE;AACA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".logo-container{\n    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));\n}\n.grid-container.logo-container{\n    padding-top: 2rem;\n}\n.logo{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    height: 150px;\n}\n\n.logo__img{\n    width: 80%;\n    height: 80%;\n    object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
