// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rich-wrapper{  
    box-sizing:border-box;
    text-align: justify;
    flex-grow: 1;
    /* width: min(40rem, 100%); */
    padding-bottom: 3rem;
    padding-top: 1rem;
    width: 100%;
    /* border-bottom: 1px solid var(--black-color-lighten); */
}

.rich-wrapper img{
    display: block;
    width: 100%;
    max-width: 400px;
    margin: 2rem auto;
}
.rich-wrapper li{
    list-style:disc;
}
.rich-wrapper.highlight{
    border: 2px solid var(--electric-blue-color-light);
    padding: 2rem;
    border-radius: 5px;
    margin: 1rem 0;
}`, "",{"version":3,"sources":["webpack://./src/components/rich-text/richText.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,YAAY;IACZ,6BAA6B;IAC7B,oBAAoB;IACpB,iBAAiB;IACjB,WAAW;IACX,yDAAyD;AAC7D;;AAEA;IACI,cAAc;IACd,WAAW;IACX,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;AACA;IACI,kDAAkD;IAClD,aAAa;IACb,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".rich-wrapper{  \n    box-sizing:border-box;\n    text-align: justify;\n    flex-grow: 1;\n    /* width: min(40rem, 100%); */\n    padding-bottom: 3rem;\n    padding-top: 1rem;\n    width: 100%;\n    /* border-bottom: 1px solid var(--black-color-lighten); */\n}\n\n.rich-wrapper img{\n    display: block;\n    width: 100%;\n    max-width: 400px;\n    margin: 2rem auto;\n}\n.rich-wrapper li{\n    list-style:disc;\n}\n.rich-wrapper.highlight{\n    border: 2px solid var(--electric-blue-color-light);\n    padding: 2rem;\n    border-radius: 5px;\n    margin: 1rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
