// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lodge-container{
    padding-top: 2rem;
    height: 100%;
    width: 100%;
}

iframe{
border: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/lodge/lodgeExample.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,WAAW;AACf;;AAEA;AACA,SAAS;AACT","sourcesContent":[".lodge-container{\n    padding-top: 2rem;\n    height: 100%;\n    width: 100%;\n}\n\niframe{\nborder: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
