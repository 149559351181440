// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery{
    padding: 1rem 0 3rem 0;
    border-bottom: 1px solid var(--black-color);
}

.gallery__images{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.gallery__info{
    margin-bottom: 2rem;
}

.gallery-img{
    height: 7rem;
    border-radius: 10px;
    border: 1px solid var(--black-color);
    overflow: hidden;
    padding: 0.25rem;
}

.gallery-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/image-gallery/gallery.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,2CAA2C;AAC/C;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,oCAAoC;IACpC,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".gallery{\n    padding: 1rem 0 3rem 0;\n    border-bottom: 1px solid var(--black-color);\n}\n\n.gallery__images{\n    display: flex;\n    flex-wrap: wrap;\n    gap: 2rem;\n}\n\n.gallery__info{\n    margin-bottom: 2rem;\n}\n\n.gallery-img{\n    height: 7rem;\n    border-radius: 10px;\n    border: 1px solid var(--black-color);\n    overflow: hidden;\n    padding: 0.25rem;\n}\n\n.gallery-img img{\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
