// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flag-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.grid-container.flag-container {
    padding-top: 2rem;
}

.country {
    display: flex;
    /* justify-content: ; */
    gap: 1rem;
    height: 50px;
    overflow: hidden;
}

.country__flag {
    width: 80px;
}

.country__info {
    white-space: nowrap;
}

.flag-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/components/countries/countries.css"],"names":[],"mappings":"AAAA;IACI,4DAA4D;AAChE;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".flag-container {\n    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n}\n\n.grid-container.flag-container {\n    padding-top: 2rem;\n}\n\n.country {\n    display: flex;\n    /* justify-content: ; */\n    gap: 1rem;\n    height: 50px;\n    overflow: hidden;\n}\n\n.country__flag {\n    width: 80px;\n}\n\n.country__info {\n    white-space: nowrap;\n}\n\n.flag-img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
