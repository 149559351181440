// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    overflow: hidden;
    padding: 0 3rem;
    --webkit-backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.carousel__content{
    max-width: 900px;
}

.carousel__content img{
    width: 100%;
    /* height: 100%; */
    max-height: 600px;
    object-fit: cover;
}

.carousel-arrow svg {
    height: 5rem;
    width: 5rem;
    transition: fill 0.3s;
}

.carousel-arrow:hover svg,
.carousel-arrow:focus svg {
    fill: var(--electric-blue-color);
}

.carousel-arrow:active svg {
    fill: var(--neon-green-color);
}

.mobile-controls{
    position: absolute;
    bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 780px) {
    .carousel{
        flex-direction: column;
        justify-content: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/carousel/carousel.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;IACT,gBAAgB;IAChB,eAAe;IACf,mCAAmC;IACnC,kCAA0B;YAA1B,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,qBAAqB;AACzB;;AAEA;;IAEI,gCAAgC;AACpC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI;QACI,sBAAsB;QACtB,uBAAuB;IAC3B;AACJ","sourcesContent":[".carousel{\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 1rem;\n    overflow: hidden;\n    padding: 0 3rem;\n    --webkit-backdrop-filter: blur(5px);\n    backdrop-filter: blur(5px);\n}\n\n.carousel__content{\n    max-width: 900px;\n}\n\n.carousel__content img{\n    width: 100%;\n    /* height: 100%; */\n    max-height: 600px;\n    object-fit: cover;\n}\n\n.carousel-arrow svg {\n    height: 5rem;\n    width: 5rem;\n    transition: fill 0.3s;\n}\n\n.carousel-arrow:hover svg,\n.carousel-arrow:focus svg {\n    fill: var(--electric-blue-color);\n}\n\n.carousel-arrow:active svg {\n    fill: var(--neon-green-color);\n}\n\n.mobile-controls{\n    position: absolute;\n    bottom: 2rem;\n    width: 100%;\n    display: flex;\n    justify-content: space-around;\n}\n\n@media screen and (max-width: 780px) {\n    .carousel{\n        flex-direction: column;\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
