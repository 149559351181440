// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publication__buttons{
    justify-content: space-around;
    gap: 1.5rem;
    margin: 2rem;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    
}
.publication__buttons.outer-buttons{
    margin-top: 2rem;
}
.publication__buttons.inner-buttons{
    border-top: 1px solid var(--black-color-light);
    padding-top: 1.5rem;
    display: none;
}
.publication__buttons.inner-buttons.visible{
    display: flex;
}
.pub-button{
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 5px;
    background-color: var(--electric-blue-color);
}
.pub-button:hover,
.pub-button:focus{
    background-color: var(--neon-green-color);
}
.pub-button.active{
    background-color: var(--neon-green-color);
}

`, "",{"version":3,"sources":["webpack://./src/components/publications/publications.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,aAAa;IACb,aAAa;IACb,eAAe;;AAEnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,8CAA8C;IAC9C,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,oBAAoB;IACpB,YAAY;IACZ,kBAAkB;IAClB,4CAA4C;AAChD;AACA;;IAEI,yCAAyC;AAC7C;AACA;IACI,yCAAyC;AAC7C","sourcesContent":[".publication__buttons{\n    justify-content: space-around;\n    gap: 1.5rem;\n    margin: 2rem;\n    margin-top: 0;\n    display: flex;\n    flex-wrap: wrap;\n    \n}\n.publication__buttons.outer-buttons{\n    margin-top: 2rem;\n}\n.publication__buttons.inner-buttons{\n    border-top: 1px solid var(--black-color-light);\n    padding-top: 1.5rem;\n    display: none;\n}\n.publication__buttons.inner-buttons.visible{\n    display: flex;\n}\n.pub-button{\n    padding: 1rem 1.5rem;\n    border: none;\n    border-radius: 5px;\n    background-color: var(--electric-blue-color);\n}\n.pub-button:hover,\n.pub-button:focus{\n    background-color: var(--neon-green-color);\n}\n.pub-button.active{\n    background-color: var(--neon-green-color);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
